import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupeeSign } from "@fortawesome/free-solid-svg-icons";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import FilterSelect from "../../components/select/FilterSelect";
import { getData, postData, updateData } from "../../api/apiDataExchange";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/monthYearPicker/MonthYearPicker.css";
import IndianMap from "../../components/IndianMap/IndianMap";
import DashBoardPaymentView from "../../views/dashBoardPayment/DashBoardPaymentView";
import GoogleMapDashboard from "../../components/googlePlacesMap/GoogleMapDashboard";
import { MultiSelect } from "react-multi-select-component";
import AnimatedCount from "./AnimatedCount";
import CustomTooltopDashboard from "../../components/tooltips/CustomTooltopDashboard";
import { ConstantUtils } from "../../utils/ConstantUtils";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import SavingEnergyPopup from "../../components/popup/SavingEnergyPopup";
import { setOptions } from "leaflet";
import ArrearsPopup from "../../components/popup/ArrearsPopup";
import IncentivePopup from "../../components/popup/IncentivePopup";

const constantUtils = new ConstantUtils();

const Dashboard = () => {
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();
  const constantUtils = new ConstantUtils();
  const [activeTab, setActiveTab] = useState(0);
  const [recommedationYears, setRecommedationYears] = useState(
    constantUtils.getLastFiveFinancialYears()
  );
  const [recommendationStates, setRecommendationStates] = useState(
    constantUtils.getRecommedationStateCodes()
  );
  const [selectedRecommendationState, setSelectedRecommendationState] =
    useState("");
  const [selectedRecommedationYear, setSelectedRecommedationYear] =
    useState("");
  const fiscalYearStartMonth = 4;
  const lastFiscalYearStartMonth =
    currentMonth >= fiscalYearStartMonth
      ? fiscalYearStartMonth
      : fiscalYearStartMonth - 12;
  const lastFiscalYearStartYear =
    currentMonth >= fiscalYearStartMonth ? currentYear - 1 : currentYear - 2;
  const lastFiscalYearEndMonth = fiscalYearStartMonth - 1;
  const lastFiscalYearEndYear =
    currentMonth >= fiscalYearStartMonth ? currentYear : currentYear - 1;
  const [disableFlag, setDisableFlag] = useState(false);
  const [meterWRTState, setMeterWRTState] = useState([]);
  const [options, setOptions] = useState([]);

  const formatDateToString = (date) => {
    if (date) {
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${month}/${year}`;
    }
  };

  const formatDateWithMonthAndYear = (date) => {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[date.getMonth()];
    const year = date.getFullYear().toString();
    return `${month}-${year}`;
  };
  function formatDate(date) {
    if (date) {
      const dd = String(date.getDate()).padStart(2, "0");
      const mm = String(date.getMonth() + 1).padStart(2, "0");
      const yyyy = String(date.getFullYear());
      return `${yyyy}-${mm}-${dd}`;
    }
  }

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [minStartDate, setMinStartDate] = useState(null);
  const [maxStartDate, setMaxStartDate] = useState(new Date());
  const [minEndDate, setMinEndDate] = useState(null);
  const [maxEndDate, setMaxEndDate] = useState(new Date());
  const [selectedLF, setSelectedLF] = useState(40);

  const [org, setOrg] = useState("");
  const [meter, setMeter] = useState("");

  const handleStartDateChange = (date) => {
    setDisableFlag(true);
    setStartDate(date);
    const minEndDate = new Date(date);
    const maxEndDate = new Date(date);
    maxEndDate.setMonth(maxEndDate.getMonth() + 11);
    setMinEndDate(minEndDate);
    setMaxEndDate(maxEndDate);
    if (endDate && date > endDate) {
      setEndDate(null);
    }

    // const newEndDate = new Date(date);
    // newEndDate.setFullYear(newEndDate.getFullYear() + 1);
    const newEndDate = new Date(date);
    newEndDate.setMonth(newEndDate.getMonth() + 11);
    setEndDate(newEndDate);

    setFilters({
      ...filters,
      startDate: formatDateToString(date),
      endDate: formatDateToString(newEndDate),
    });
    setSelectedRange({
      start: formatDateToString(date),
      end: newEndDate ? formatDateToString(newEndDate) : null,
    });
    setFilterTrigger(false);
  };

  const handleEndDateChange = (date) => {
    setDisableFlag(true);
    setEndDate(date);

    const minStartDate = new Date(date);
    minStartDate.setMonth(minStartDate.getMonth() - 11);
    // setMinStartDate(minStartDate);
    setMaxStartDate(date);

    if (startDate && date < startDate) {
      setStartDate(null);
    }

    setFilters({ ...filters, endDate: formatDateToString(date) });
    setSelectedRange({
      start: startDate ? formatDateToString(startDate) : null,
      end: formatDateToString(date),
    });
    setFilterTrigger(false);
  };

  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [selectedRange, setSelectedRange] = useState({
    start: null,
    end: null,
  });
  const [stateList, setStateList] = useState([]);
  const [stateCode, setStateCode] = useState([]);
  const [filters, setFilters] = useState({
    startDate: formatDateToString(startDate),
    endDate: formatDateToString(endDate),
    organisationId: org,
    meterId: meter,
    stateCode: stateCode,
  });
  const [organisationList, setOrganisationList] = useState([]);
  const [dashboardStats, setDashboardStats] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const [factoryList, setFactoryList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [coordinates, setCoordinates] = useState([]);
  const [unitNames, setUnitNames] = useState([]);
  const [recLoader, setRecLoader] = useState(false);
  const [unitData, setUnitData] = useState([]);
  const [lfData, setLFData] = useState();
  const [selectedUnit, setSelectedUnit] = useState("");
  const [savingsThroughGreen, setSavingsThroughGreen] = useState(0);
  const [savingsThroughGreenMeterWise, setSavingsThroughGreenMeterWise] =
    useState([]);
  const [savingEnergyLoader, setSavingEnergyLoader] = useState(false);
  const [openGreenSavingsPopup, setOpenGreenSavingsPopup] = useState(false);
  const [openArrearsPopup, setOpenArrearsPopup] = useState(false);
  const [openIncentivePopup, setOpenIncentivePopup] = useState(false);
  const [filterTrigger, setFilterTrigger] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [disableFilter, setDisableFilter] = useState(false);
  const [startDateToShow, setStartDateToShow] = useState(startDate);
  const [endDateToShow, setEndDateToShow] = useState(endDate);
  const [statsInitialLoad, setstatsInitialLoad] = useState(true);

  useEffect(() => {
    const getdata = async () => {
      const payload = {
        buyerId: localStorage.getItem("userId"),
      };
      const response = await postData(
        `${process.env.REACT_APP_API_URL}/organisation/list`,
        payload
      );

      if (response?.data?.data?.results?.length > 0) {
        setOrg(response?.data?.data?.results[0]?._id);
        setOrganisationList(response?.data?.data?.results);

        setFilters({
          ...filters,
          organisationId: response?.data?.data?.results[0]?._id,
        });
      }
    };
    getdata();
  }, []);

  useEffect(() => {
    const getdata = async () => {
      let data = {};
      console.log("getdatacalled");

      if (filters.startDate) {
        data.startDate = filters.startDate;
      }
      if (filters.endDate) {
        data.endDate = filters.endDate;
      }
      if (filters.organisationId) {
        data.organisationId = filters.organisationId;
      }
      if (filters.stateCode.length > 0) {
        data.stateCode = filters.stateCode;
      }
      if (filters.meterId?.length > 0) {
        data.meterId = filters.meterId;
      }
      console.log({ data, filters, initialLoad, filterTrigger });

      if (data.organisationId && (statsInitialLoad || filterTrigger)) {
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/visualization/stats`,
          data
        );
        setDashboardStats(response?.data?.data);
        setstatsInitialLoad(false);
      }
    };
    console.log("statsCalled");
    getdata();
  }, [filterTrigger, initialLoad, organisationList, stateCode, filters]);

  const getFinancialYearDates = (financialYear) => {
    const yearParts = financialYear.slice(2).split("-");
    const startYear = parseInt(yearParts[0]);
    const endYear = parseInt(yearParts[1]) + 2000; // Add 2000 to get the correct year format

    const startDate = `04/${startYear}`;
    const endDate = `03/${endYear}`;

    return { startDate, endDate };
  };

  useEffect(() => {
    const getdata = async () => {
      let data = {};

      if (selectedRecommedationYear !== "") {
        const filterResult = getFinancialYearDates(selectedRecommedationYear);
        data.startDate = filterResult.startDate;
        data.endDate = filterResult.endDate;
      }
      if (filters.organisationId) {
        data.organisationId = filters.organisationId;
      }
      if (selectedRecommendationState) {
        data.stateCode = selectedRecommendationState;
      }

      if (
        data.organisationId &&
        selectedRecommendationState !== "" &&
        (initialLoad || filterTrigger || selectedRecommedationYear !== "") &&
        !savingEnergyLoader
      ) {
        setSavingEnergyLoader(true);
        setDisableFilter(true);
        const [iexDataResponse, constantPriceResponse] = await Promise.all([
          postData(
            `${process.env.REACT_APP_API_URL}/recommendation/saveEnergy?pricetype=iexdata`,
            data
          ),
          postData(
            `${process.env.REACT_APP_API_URL}/recommendation/saveEnergy?pricetype=constantprice`,
            data
          ),
        ]);

        if (
          iexDataResponse?.data?.statusCode === 200 &&
          constantPriceResponse?.data?.statusCode === 200
        ) {
          const totalSaveGreenEnergyByIex = iexDataResponse?.data?.data?.reduce(
            (total, entry) => total + entry.saveGreenEnergy,
            0
          );

          const totalSaveGreenEnergyByConstantPrice =
            constantPriceResponse?.data?.data?.reduce(
              (total, entry) => total + entry.saveGreenEnergy,
              0
            );

          if (totalSaveGreenEnergyByIex > totalSaveGreenEnergyByConstantPrice) {
            setSavingsThroughGreen(
              totalSaveGreenEnergyByIex >= 0 ? totalSaveGreenEnergyByIex : 0
            );
            setSavingsThroughGreenMeterWise(iexDataResponse?.data?.data);
            setSavingEnergyLoader(false);
            setDisableFilter(false);
            setInitialLoad(false);
            setFilterTrigger(false);
          } else {
            setFilterTrigger(false);
            setDisableFilter(false);
            setInitialLoad(false);
            setSavingEnergyLoader(false);
            setSavingsThroughGreen(
              totalSaveGreenEnergyByConstantPrice >= 0
                ? totalSaveGreenEnergyByConstantPrice
                : 0
            );
            setSavingsThroughGreenMeterWise(constantPriceResponse?.data?.data);
          }
        } else {
          const totalSaveGreenEnergy = iexDataResponse?.data?.data?.reduce(
            (total, entry) => total + entry.saveGreenEnergy,
            0
          );
          setSavingsThroughGreen(
            totalSaveGreenEnergy >= 0 ? totalSaveGreenEnergy : 0
          );
          setSavingsThroughGreenMeterWise(iexDataResponse?.data?.data);
          setSavingEnergyLoader(false);
          setDisableFilter(false);
          setFilterTrigger(false);
        }
      } else {
        // console.log('entry');
        setSavingsThroughGreen(0);
        setSavingEnergyLoader(false);
        setDisableFilter(false);
        setFilterTrigger(false);
      }
    };
    if (filterTrigger || initialLoad || selectedRecommedationYear !== "") {
      // console.log("getting Data");
      getdata();
    }
  }, [
    selectedRecommedationYear,
    selectedRecommendationState,
    filters.organisationId,
  ]);

  useEffect(() => {
    const getdata = async () => {
      let data = {};

      if (filters.startDate) {
        data.startDate = filters.startDate;
      }
      if (filters.endDate) {
        data.endDate = filters.endDate;
      }
      if (filters.organisationId) {
        data.organisationId = filters.organisationId;
      }
      if (filters.stateCode.length > 0) {
        data.stateCode = filters.stateCode;
      }
      if (filters.meterId?.length > 0) {
        data.meterId = filters.meterId;
      }
      if (selectedLF !== 0) {
        data.lfType = "lteq";
        data.lfPercentage = parseInt(selectedLF);
      }
      if (data.organisationId && (initialLoad || selectedLF)) {
        const response = await postData(
          `${process.env.REACT_APP_API_URL}/visualization/lfStats`,
          data
        );

        setLFData(response?.data?.data.totalRecords);
      }
    };
    console.log({ initialLoad, selectedLF });
    if (initialLoad === true || selectedLF) {
      getdata();
    }
  }, [
    filterTrigger,
    initialLoad,
    organisationList,
    selectedLF,
    stateCode,
    meter,
    startDate,
    endDate,
  ]);

  console.log(filters.stateCode, "data");

  useEffect(() => {
    if (stateCode?.length > 0 || meter?.length > 0) {
      setFilters({
        ...filters,
        meterId: [],
        stateCode: [],
      });
    }
    setStateCode([]);
    setMeter([]);

    const getTreeViewData = async () => {
      if (org !== "") {
        const response = await getData(
          `${process.env.REACT_APP_API_URL}/organisation/tree/${org}`
        );
        const allFactories = response?.data?.factories?.flatMap(
          (factories) => factories || []
        );
        setFactoryList(allFactories);

        const allUnits = allFactories.flatMap((factory) => factory.units);
        // const data = allUnits.map((unit)=> unit._id);
        // setSelectedUnit(data);

        const unitsData = allUnits
          ?.map((unit) => ({
            name: unit?.name,
            coordinates: unit?.location?.coordinates,
          }))
          .filter(
            (unit) =>
              unit?.coordinates &&
              unit?.coordinates?.length === 2 &&
              unit?.coordinates?.every(
                (coord) => coord !== null && coord !== undefined
              )
          );
        setUnitData(unitsData);

        // const extractedInfo = allUnits?.map(unit =>  unit.state) || [];

        const extractedInfo = Array.from(
          new Map(allUnits.map((unit) => [unit.stateCode, unit.state])).values()
        );
        const extractedInfoSorted = extractedInfo.sort((a, b) => {
          if (a.code === "IN-MH") return -1;
          if (b.code === "IN-MH") return 1;

          return a.name.localeCompare(b.name);
        });

        setStateList(extractedInfoSorted);
        const stateCodeList = extractedInfoSorted?.map((state) => {
          return { label: state?.name, value: state?.code };
        });
        setStateCode(stateCodeList);

        // Collect all meters directly from the selected organizations
        const allMeters = allFactories.flatMap((factory) =>
          factory.units.flatMap((unit) => unit.meters)
        );

        // filter by state
        const allMetersWRTState = allUnits.map((e) => {
          return {
            id: e._id,
            stateCode: e.stateCode,
            meters: e.meters,
          };
        });
        setMeterWRTState(allMetersWRTState);

        const allMeterIds = allMeters.map((meter) => meter._id);
        const allStateCodes = extractedInfo.map((stateCode) => stateCode.code);
        const allMetersSelected = allMeters?.map((meter) => ({
          label: meter.consumerNo,
          value: meter._id,
        }));

        const allMeterIdsWRTState = allMetersWRTState
          ?.filter((state) => state.stateCode === "IN-MH")
          ?.flatMap((item) => item.meters.map((meter) => meter._id));

        setMeter(
          allMetersWRTState[0]?.meters?.flatMap((meter) => ({
            label: meter.consumerNo,
            value: meter._id,
          }))
        );
        setFilters({
          ...filters,
          stateCode: allStateCodes,
        });
        setMeterList(allMeters);
      }
    };
    getTreeViewData();
  }, [org]);

  const handleRangeChange = (range) => {
    setSelectedRange(range);
  };

  const handleOrgChange = (selectedOrg) => {
    setOrg(selectedOrg);
    if (selectedOrg) {
      setFilters({ ...filters, organisationId: selectedOrg });
    }
  };

  const handleStateChange = (selectedState) => {
    setDisableFlag(true);
    setMeter([]);
    setStateCode(selectedState);
    setFilterTrigger(false);
    if (selectedState) {
      if (stateList.length === selectedState.length) {
        setFilters((prev) => {
          return { ...prev, stateCode: [], meterId: [] };
        });
      } else {
        setFilters({
          ...filters,
          stateCode: selectedState.map((state) => state.value),
        });
      }
    }
  };

  function formatNumber(number) {
    if (typeof number !== "number") return number;

    // Round the number to the nearest integer
    const roundedNumber = Math.round(number);

    // Convert the number to a string and add commas for thousands separators
    // const formattedNumber = roundedNumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return roundedNumber.toLocaleString("en-IN");
    // return formattedNumber;
  }

  // const options = meterList?.map(meter => ({
  //   label: meter.consumerNo,
  //   value: meter._id,
  // }));

  useEffect(() => {
    const newOptions = meterWRTState.flatMap((e) => {
      if (stateCode.some((state) => state.value === e.stateCode)) {
        const id = e.id;
        return e.meters.map((meter) => ({
          unitId: id,
          label: meter.formattedConsumerName,
          value: meter._id,
        }));
      }
      return [];
    });
    setMeter(newOptions);
    setOptions(newOptions);
  }, [stateCode, meterWRTState]);

  const handleFilterClick = () => {
    if (options?.length === filters?.meterId?.length) {
      setFilters((prev) => {
        return { ...prev, meterId: [] };
      });
    }
    setDisableFlag(false);
    setInitialLoad(false);
    setStartDateToShow(startDate);
    setEndDateToShow(endDate);
    setFilterTrigger((prev) => !prev); // Toggle filter trigger state to re-run the useEffect hooks
  };

  const handleFilterReset = () => {
    setOrg("");
    setStateCode([]);
    setMeter([]);

    setFilters({
      organisationId: "",
      stateCode: "",
      meterId: [],
    });
    setFilterTrigger(false);
  };

  const handleChange = (selectedOptions) => {
    setDisableFlag(true);
    setMeter(selectedOptions);
    if (selectedOptions) {
      if (options.length === selectedOptions.length) {
        setFilters((prev) => {
          return { ...prev, meterId: [] };
        });
      } else {
        setFilters({
          ...filters,
          meterId: selectedOptions.map((meter) => meter.value),
        });
        const data = selectedOptions.map((unit)=>unit.unitId);
        setSelectedUnit(data);
      }
    }
    setFilterTrigger(false);
  };

  const handleSelectChange = (event) => {
    setSelectedLF(event.target.value);
  };

  const buildURL = () => {
    let url = `/power-consumption?org=${filters.organisationId}&lf=${selectedLF}&lfType=lteq&from=dashboard`;
    if (startDate) {
      url += `&startDate=${formatDateToString(startDate)}`;
    }

    if (endDate) {
      url += `&endDate=${formatDateToString(endDate)}`;
    }
    if (filters.stateCode.length > 0) {
      let statecodeArray = filters.stateCode;
      if (stateList.length === filters.stateCode.length) {
        statecodeArray = [];
      }
      url += `&state=[${statecodeArray}]`;
    }

    if (filters.meterId.length > 0) {
      let meterIDArray = filters.meterId;
      if (options?.length === filters?.meterId?.length) {
        meterIDArray = [];
      }
      url += `&consumers=[${meterIDArray}]`;
    }
    return url;
  };
  const url = buildURL();

  useEffect(() => {
    if (recommedationYears.length > 0) {
      setSelectedRecommedationYear(recommedationYears[1]);
    }
  }, [recommedationYears]);

  useEffect(() => {
    if (recommendationStates.length > 0) {
      console.log({ selectedRecommendationState });
      setSelectedRecommendationState(recommendationStates[0].stateCode);
    }
  }, [recommendationStates]);

  const buildAnalyticsUrl = ({
    org,
    filters,
    stateList,
    startDate,
    endDate,
    selectedUnit,
    formatDate,
  }) => {
    // URL for when no dates are selected
    const urlNoDate = `/analytics?orgId=${org}${
      filters.stateCode.length !== stateList.length
        ? `&stateCode=[${filters.stateCode}]`
        : ""
    }${
      startDate && endDate
        ? `&startDate=${formatDate(startDate)}&endDate=${formatDate(endDate)}`
        : ""
    }`;

    // URL for when startDate and endDate exist
    const urlWithDates =
      startDate && endDate
        ? `/analytics?unitId=${selectedUnit}&orgId=${org}&type=month&startDate=${formatDate(
            startDate
          )}&endDate=${formatDate(endDate)}${
            filters.stateCode.length !== stateList.length
              ? `&stateCode=[${filters.stateCode}]`
              : ""
          }`
        : urlNoDate;

    return urlWithDates;
  };
  const getAnalyticsUrl = buildAnalyticsUrl({
    org,
    filters,
    stateList,
    startDate,
    endDate,
    selectedUnit,
    formatDate,
  });

  return (
    <div className="lg:bg-[#ebedeb] mt-0 pt-[58px] sm:pt-[80px] lg:h-screen">
      <div className="lg:max-w-[1920px]  m-auto flex ">
        <div className="hidden sm:block">
          <Sidebar />
        </div>

        <div
          className={`${
            isSidebarOpen ? "lg:ml-[300px]" : "sm:ml-[80px]"
          } w-full max-sm:px-[10px]  lg:pr-[10px] lg:pl-[10px] transition-width duration-300`}
        >
          <div className=" sm:flex w-full ">
            <div className="w-full">
              <div className="flex mt-3 sm:mt-0 flex-row w-[100%] sm:w-[100%]  pl-2  flex-wrap justify-between sm:justify-start lg:justify-between dashboard border-gray-700 bg-[white] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] p-2 rounded-xl">
                <div className="w-[45%] sm:w-[30%] lg:w-[17%] z-40">
                  <FilterSelect
                    label={"Organisation Name"}
                    value={org}
                    options={organisationList?.map((organisation) => {
                      return {
                        label: organisation?.name,
                        value: organisation?._id,
                      };
                    })}
                    onChange={(e) => {
                      handleOrgChange(e.target.value);
                      setDisableFlag(true);
                    }}
                  />
                </div>
                <div className="w-[45%] sm:w-[30%] lg:w-[17%] sm:mx-3 lg:mx-0 max-w-[200px] z-40 ">
                  <MultiSelect
                    options={stateList?.map((state) => {
                      return { label: state?.name, value: state?.code };
                    })}
                    value={stateCode ?? []}
                    onChange={(selected) => {
                      handleStateChange(selected);
                    }}
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: "Select state code",
                      allItemsAreSelected: "All States selected",
                    }}
                    disableSearch={true}
                    className="custom-multi-select"
                  />
                </div>
                <div className="w-[45%] sm:w-[30%] lg:w-[17%] sm:mx-3 lg:mx-0 max-w-[200px] z-40 ">
                  <MultiSelect
                    options={options}
                    value={meter ?? []}
                    onChange={(selected) => {
                      handleChange(selected);
                    }}
                    labelledBy="Select"
                    overrideStrings={{
                      selectSomeItems: "Select Consumer No",
                      allItemsAreSelected: "All Consumer selected",
                    }}
                    disableSearch={true}
                    className="custom-multi-select fixed-width"
                  />
                </div>

                <div className="w-[45%] sm:w-[30%] lg:w-[15%] mt-[10px] lg:mt-0 z-40">
                  <DatePicker
                    selected={startDate}
                    onChange={handleStartDateChange}
                    dateFormat="MMM-yyyy"
                    showMonthYearPicker
                    placeholderText="Start Month"
                    minDate={minStartDate}
                    maxDate={maxStartDate}
                  />
                </div>
                <div className="w-[45%] sm:w-[30%] lg:w-[15%] mt-[10px] lg:mt-0 z-39 sm:mx-3 lg:mx-0">
                  <DatePicker
                    selected={endDate}
                    onChange={handleEndDateChange}
                    dateFormat="MMM-yyyy"
                    showMonthYearPicker
                    placeholderText="End Month"
                    minDate={startDate}
                    maxDate={maxEndDate}
                  />
                </div>
                <div className="w-[45%] sm:w-[30%] lg:w-[9%] z-[1] ml-3 flex gap-1 justify-end h-[40px] mt-2.5 lg:mt-0">
                  {!disableFlag || disableFilter ? (
                    <button className="px-4 py-2 bg-[#b7d8ca] cursor-default text-white rounded-lg">
                      Apply
                    </button>
                  ) : (
                    <button
                      onClick={handleFilterClick}
                      className="px-4 py-2 bg-[#067C4E] text-white rounded-lg"
                    >
                      Apply
                    </button>
                  )}
                  {/* <button onClick={handleFilterReset} className="px-4 py-2 bg-[#067C4E] text-white rounded-lg">
                Reset
              </button> */}
                </div>
              </div>
              <div
                className={`mt-2 sm:mt-1 lg:w-[100%] sm:w-[95%] flex flex-col lg:flex-row`}
              >
                <div className="flex flex-col lg:w-[100%] sm:pl-4 lg:pl-0 mb-5 sm:mb-0">
                  <div className="flex flex-col sm:flex-row sm:space-x-[1%] mt-2 w-[100%] h-[100%]">
                    <div className="hidden  lg:block sm:w-[100%] sm:h-[100%]  lg:w-[45%] bg-white p-2 border lg:border-gray-300 rounded-[20px] lg:shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] -z-0 ">
                      {process.env.REACT_APP_ENVIRONMENT === "local" ? (
                        <img
                          src="./graph.png"
                          alt=""
                          className="h-[100%] w-[100%]"
                        />
                      ) : (
                        <GoogleMapDashboard address={unitData} />
                      )}
                    </div>

                    <div className="w-[100%]  h-[100%]  lg:w-[55%]  mx-auto sm:mx-0 flex flex-col border-gray-700 lg:bg-[white] p-2 lg:pt-2 rounded-xl lg:shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)]">
                      <div className=" grid grid-cols-2 h-full sm:grid-cols-3 justify-between  gap-2 w-[100%] mx-auto">
                        <div className="w-[100%] sm:w-[100%] bg-[#E2F5EC] p-3 text-center border relative border-gray-300 rounded-[20px]">
                          <Link to={getAnalyticsUrl}>
                            <div className="text-black lg:text-[15px] sm:pt-5 sm:text-[10px] font-medium min-h-10">
                              Units Consumed
                              <div className="absolute top-0 right-2 z-10 dashboard-tooltip">
                                <CustomTooltopDashboard
                                  text={
                                    constantUtils.getDashboardToolTipData()
                                      .DashboardUnitConsumed.text
                                  }
                                />
                              </div>
                            </div>
                            <div className="text-lg hover:underline hover:text-green-600 text-black font-bold mt-2 sm:pt-5 number-container min-h-10">
                              <span>
                                {dashboardStats?.totalConsumption ? (
                                  <AnimatedCount
                                    value={
                                      dashboardStats.totalConsumption.value
                                    }
                                    duration={0.6}
                                    formatNumber={formatNumber}
                                  />
                                ) : (
                                  "0"
                                )}
                              </span>

                              <span className="ml-1 break-normal">
                                {dashboardStats?.totalConsumption?.unit}
                              </span>
                            </div>
                          </Link>
                        </div>

                        <div className="w-[100%] sm:w-[100%] bg-[#E2F5EC] p-3 text-center relative border border-gray-300 rounded-[20px]">
                          <Link to={getAnalyticsUrl}>
                            <div className="text-black lg:text-[15px] sm:pt-5 sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                              Total Bill Amount
                              <div className="absolute top-0 right-2 -z-0 dashboard-tooltip">
                                <CustomTooltopDashboard
                                  text={
                                    constantUtils.getDashboardToolTipData()
                                      .DashboardTotalBillAmount.text
                                  }
                                />
                              </div>
                            </div>
                            <div className="text-lg hover:underline hover:text-green-600 text-black font-bold mt-2 sm:pt-3 number-container min-h-10">
                              <div>
                                <FontAwesomeIcon
                                  className="mr-1"
                                  icon={faIndianRupeeSign}
                                />
                                {dashboardStats?.totalBillAmount ? (
                                  <AnimatedCount
                                    value={dashboardStats.totalBillAmount.value}
                                    duration={0.6}
                                    formatNumber={formatNumber}
                                  />
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </Link>
                        </div>

                        <div className="w-[100%] sm:w-[100%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200 my-2 sm:my-0 rounded-[20px] ">
                          <div className="text-black lg:text-[15px] sm:text-[10px] sm:pt-5 font-medium min-h-10 lg:min-h-14">
                            TOD Charges
                            <div className="absolute top-0 right-2 dashboard-tooltip">
                              <CustomTooltopDashboard
                                text={
                                  constantUtils.getDashboardToolTipData()
                                    .DashboardTodCharges.text
                                }
                              />
                            </div>
                          </div>

                          <div
                            className={`${
                              dashboardStats?.todTariffEc?.value > 0 > 0
                                ? "text-red-500"
                                : "text-green-500"
                            } font-bold number-container mt-2  sm:pt-3 min-h-10 `}
                          >
                            <FontAwesomeIcon
                              className="mr-1"
                              icon={faIndianRupeeSign}
                            />
                            {dashboardStats?.todTariffEc ? (
                              <AnimatedCount
                                value={dashboardStats.todTariffEc.value}
                                duration={0.6}
                                formatNumber={formatNumber}
                              />
                            ) : (
                              "0"
                            )}
                          </div>
                        </div>

                        <div className="w-[100%] sm:w-[100%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200 rounded-[20px]">
                          <Link to={getAnalyticsUrl}>
                            <div className="text-black lg:text-[15px] sm:text-[10px] sm:pt-5 font-medium min-h-10 lg:min-h-14">
                              Avg Monthly Bill
                              <div className="absolute top-0 right-2 dashboard-tooltip">
                                <CustomTooltopDashboard
                                  text={
                                    constantUtils.getDashboardToolTipData()
                                      .DashboardAvgMonthlyBill.text
                                  }
                                />
                              </div>
                            </div>
                            <div className="text-lg hover:underline hover:text-green-600 text-black font-bold mt-2 sm:pt-5 number-container min-h-10 ">
                              <div>
                                <FontAwesomeIcon
                                  className="mr-1"
                                  icon={faIndianRupeeSign}
                                />
                                {dashboardStats?.avgMonthlyBill ? (
                                  <AnimatedCount
                                    value={dashboardStats.avgMonthlyBill.value}
                                    duration={0.6}
                                    formatNumber={formatNumber}
                                  />
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </Link>
                        </div>
                        <div className="w-[100%] sm:w-[100%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200 rounded-[20px]">
                          <Link to={getAnalyticsUrl}>
                            <div className="lg:text-[15px] sm:pt-5 sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                              Avg Monthly Consumption
                              <div className="absolute top-0 right-2 dashboard-tooltip">
                                <CustomTooltopDashboard
                                  text={
                                    constantUtils.getDashboardToolTipData()
                                      .DashboardTodAvgMonthlyConsumption.text
                                  }
                                />
                              </div>
                            </div>
                            <div className="text-lg hover:underline hover:text-green-600 text-black font-bold mt-2 sm:pt-3 number-container min-h-10">
                              {dashboardStats?.avgMonthlyConsumption ? (
                                <AnimatedCount
                                  value={
                                    dashboardStats.avgMonthlyConsumption.value
                                  }
                                  duration={0.6}
                                  formatNumber={formatNumber}
                                />
                              ) : (
                                "0"
                              )}
                              <span className="ml-1 break-normal">
                                {dashboardStats?.avgMonthlyConsumption?.unit}
                              </span>
                            </div>
                          </Link>
                        </div>
                        <div className="w-[100%] sm:w-[100%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200  rounded-[20px] ">
                          <div className="text-black lg:text-[15px] sm:text-[10px] sm:pt-5 font-medium min-h-10 lg:min-h-14">
                            Emission (tCO<sub>2e</sub>)
                            <div className="absolute top-0 right-2 dashboard-tooltip">
                              <CustomTooltopDashboard
                                text={
                                  constantUtils.getDashboardToolTipData()
                                    .DashboardEmmission.text
                                }
                              />
                            </div>
                          </div>
                          <div className="text-lg font-bold mt-2 sm:pt-5">
                            <div className="text-lg text-black font-bold number-container min-h-10 ">
                              {dashboardStats?.carbonFootprint ? (
                                <AnimatedCount
                                  value={dashboardStats.carbonFootprint}
                                  duration={0.6}
                                  formatNumber={formatNumber}
                                />
                              ) : (
                                "0"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="w-[100%] sm:w-[100%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200  rounded-[20px] ">
                          <div className="text-black pt-4 lg:text-[15px]  sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                            Incentive
                            <div className="absolute top-0 right-2 dashboard-tooltip">
                              <CustomTooltopDashboard
                                text={
                                  constantUtils.getDashboardToolTipData()
                                    .DashboardIncentive.text
                                }
                              />
                            </div>
                          </div>
                          <div className="text-lg font-bold sm:mt-4 ">
                            <div
                              onClick={() =>
                                setOpenIncentivePopup(!openIncentivePopup)
                              }
                              className="text-lg cursor-pointer hover:underline hover:text-green-600 text-black font-bold number-container min-h-10 "
                            >
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faIndianRupeeSign}
                              />
                              {dashboardStats?.totalIncentiveSum ? (
                                <AnimatedCount
                                  value={dashboardStats.totalIncentiveSum.value}
                                  duration={0.6}
                                  formatNumber={formatNumber}
                                />
                              ) : (
                                "0"
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="w-[100%] sm:w-[100%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200  rounded-[20px] ">
                          <div className="text-black pt-4 lg:text-[15px]  sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                            Penalty
                            <div className="absolute top-0 right-2 dashboard-tooltip">
                              <CustomTooltopDashboard
                                text={
                                  constantUtils.getDashboardToolTipData()
                                    .DashboardPanalty.text
                                }
                              />
                            </div>
                          </div>
                          <div className="text-lg font-bold sm:mt-4 ">
                            <div
                              onClick={() =>
                                setOpenArrearsPopup(!openArrearsPopup)
                              }
                              className="text-lg cursor-pointer hover:underline hover:text-green-600 text-black font-bold number-container min-h-10 "
                            >
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faIndianRupeeSign}
                              />
                              {dashboardStats?.totalPenaltySum ? (
                                <AnimatedCount
                                  value={dashboardStats.totalPenaltySum.value}
                                  duration={0.6}
                                  formatNumber={formatNumber}
                                />
                              ) : (
                                "0"
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="w-[100%] sm:w-[100%] bg-[#E2F5EC] p-3 text-center border relative border-gray-200  rounded-[20px] ">
                          <div className="text-black pt-4  lg:text-[15px] sm:text-[10px] font-medium min-h-10 lg:min-h-14">
                            Arrears
                            <div className="absolute top-0 right-2 dashboard-tooltip">
                              <CustomTooltopDashboard
                                text={
                                  constantUtils.getDashboardToolTipData()
                                    .DashboardArrears.text
                                }
                              />
                            </div>
                          </div>
                          <div className="text-lg font-bold mt-6 sm:mt-4 pb-2 sm:pb-0">
                            <div className="text-lg text-black font-bold number-container min-h-10 ">
                              <FontAwesomeIcon
                                className="mr-1"
                                icon={faIndianRupeeSign}
                              />
                              {dashboardStats?.arrears ? (
                                <AnimatedCount
                                  value={dashboardStats.arrears.value}
                                  duration={0.6}
                                  formatNumber={formatNumber}
                                />
                              ) : (
                                "0"
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className="flex flex-col sm:flex-row pb-2">
                        <div className="w-[100%] sm:w-[33%] bg-[#E2F5EC] p-3 text-center mr-2 relative my-5  sm:my-0 border border-gray-300 rounded-[20px] cursor-pointer">
                          <div className="flex flex-row items-center justify-center">
                            <div className="font-medium">Load Factor :</div>
                            <select
                              className="border border-gray-300 bg-[#E2F5EC] w-auto rounded-lg p-2 ml-2"
                              value={selectedLF}
                              onChange={handleSelectChange}
                            >
                              <option value="">Select LF</option>
                              <option value={10}>10%</option>
                              <option value={20}>20%</option>
                              <option value={30}>30%</option>
                              <option value={40}>40%</option>
                              <option value={50}>50%</option>
                              <option value={60}>60%</option>
                              <option value={70}>70%</option>
                              <option value={80}>80%</option>
                              <option value={90}>90%</option>
                              <option value={100}>100%</option>
                            </select>
                          </div>
                          <Link to={url}>
                            <div className="flex flex-row items-center justify-center mt-4 min-h-10 lg:min-h-10">
                              <div className="text-black lg:text-[15px] mt-2 sm:text-[10px] font-medium ">
                                Number of Bills : {lfData}
                              </div>
                              <div className="text-lg text-black font-bold"></div>
                            </div>
                            <div className="absolute top-0 right-2 dashboard-tooltip">
                              <CustomTooltopDashboard
                                text={
                                  constantUtils.getDashboardToolTipData()
                                    .DashboardLoadFactor.text
                                }
                              />
                            </div>
                          </Link>
                        </div>

                        <div
                          className={`w-[100%] sm:w-[35%] bg-[#c0eed8] p-3 text-center border rounded-[20px] relative ${
                            savingEnergyLoader
                              ? "cursor-not-allowed"
                              : "cursor-pointer"
                          }`}
                          onClick={() => {
                            if (
                              savingsThroughGreenMeterWise &&
                              savingsThroughGreenMeterWise?.length > 0 &&
                              !savingEnergyLoader
                            ) {
                              setOpenGreenSavingsPopup(true);
                              setActiveTab(0);
                            }
                          }}
                        >
                          <div className="text-black lg:text-[16px] mt-2 font-bold sm:text-[10px] min-h-12 sm:min-h-15">
                            Saving through green energy
                            <div className="font-medium sm:text-[8px] mt-1 lg:text-[13px] text-center">
                              ({formatDateWithMonthAndYear(startDateToShow)} To{" "}
                              {formatDateWithMonthAndYear(endDateToShow)})
                            </div>
                        
                            <div className="absolute top-0 right-2 dashboard-tooltip">
                              <CustomTooltopDashboard
                                text={
                                  constantUtils.getDashboardToolTipData()
                                    .DashboardSaving.text
                                }
                              />
                            </div>
                          </div>

                          <div className="text-lg text-green-500 font-semibold mt-2 lg:mt-6 number-container min-h-10 ">
                            <div>
                              {savingEnergyLoader ? (
                                <div className="w-[50%] h-[50%] mx-auto my-auto">
                                  <LoaderComponent />
                                </div>
                              ) : (
                                <>
                                  <FontAwesomeIcon
                                    className="mr-1"
                                    icon={faIndianRupeeSign}
                                  />
                                  {Number(savingsThroughGreen)?.toLocaleString(
                                    "en-IN"
                                  )}
                                </>
                              )}
                            </div>
                          </div>
                          <p className="text-xs text-gray-800">
                            Select "Maharashtra" to get green power
                            recommendation
                          </p>
                        </div>
c                      </div> */}
                      <SavingEnergyPopup
                        setOpenGreenSavingsPopup={setOpenGreenSavingsPopup}
                        openGreenSavingsPopup={openGreenSavingsPopup}
                        savingsThroughGreenMeterWise={
                          savingsThroughGreenMeterWise
                        }
                        savingsThroughGreen={savingsThroughGreen}
                        selectedRecommendationState={
                          selectedRecommendationState
                        }
                        selectedRecommedationYear={selectedRecommedationYear}
                        filters={filters}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                      <ArrearsPopup
                        setOpenArrearsPopup={setOpenArrearsPopup}
                        openArrearsPopup={openArrearsPopup}
                        savingsThroughGreenMeterWise={
                          savingsThroughGreenMeterWise
                        }
                        dashboardStats={dashboardStats}
                        savingsThroughGreen={savingsThroughGreen}
                        selectedRecommendationState={
                          selectedRecommendationState
                        }
                        selectedRecommedationYear={selectedRecommedationYear}
                        filters={filters}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                      <IncentivePopup
                        setOpenIncentivePopup={setOpenIncentivePopup}
                        openIncentivePopup={openIncentivePopup}
                        savingsThroughGreenMeterWise={
                          savingsThroughGreenMeterWise
                        }
                        dashboardStats={dashboardStats}
                        savingsThroughGreen={savingsThroughGreen}
                        selectedRecommendationState={
                          selectedRecommendationState
                        }
                        selectedRecommedationYear={selectedRecommedationYear}
                        filters={filters}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                      />
                    </div>
                  </div>
                  <div className="w-[100%]  sm:mt-2 lg:px-3 sm:pl-4 lg:pl-0 lg:bg-white p-2   lg:border lg:border-gray-300 rounded-[20px] lg:shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)]">
                    <div className="flex flex-row  lg:mt-0">
                      <div className="w-[100%] flex flex-row  lg:px-3 lg:pl-4">
                        <div className="w-[100%] border-blue-800 gap-[2px] flex flex-row justify-between flex-wrap">
                          <div className="w-[100%] md:w-[23%] lg:my-0  lg:mr-1  bg-[white] p-3 text-center mr-2   border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] ">
                            <div className="text-black lg:text-[13px] sm:text-[12px] font-medium min-h-2">
                              Total Number of Locations
                            </div>
                            <div className="text-lg text-black font-bold mt-2">
                              {dashboardStats?.unitCount ? (
                                <AnimatedCount
                                  value={dashboardStats.unitCount}
                                  duration={0.6}
                                  formatNumber={formatNumber}
                                />
                              ) : (
                                "0"
                              )}
                            </div>
                          </div>

                          <div className="w-[100%]  md:w-[25%] my-5 sm:my-0 lg:my-0 bg-white p-3 text-center border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] ">
                            <div className="text-black lg:text-[13px] sm:text-[12px] font-medium min-h-2">
                              Total number of Consumers
                            </div>
                            <div className="text-lg text-black font-bold mt-2">
                              <div>
                                {dashboardStats?.unitCount ? (
                                  <AnimatedCount
                                    value={dashboardStats.meterCount}
                                    duration={0.6}
                                    formatNumber={formatNumber}
                                  />
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="w-[100%]  md:w-[25%] sm:my-5 lg:my-0 bg-white p-3 text-center mr-2 lg:mr-0 border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] ">
                            <div className="text-black lg:text-[13px] sm:text-[12px] font-medium min-h-2">
                              Total Number of Bills for Period
                            </div>
                            <div className="text-lg text-black font-bold mt-2">
                              {dashboardStats?.unitCount ? (
                                <AnimatedCount
                                  value={dashboardStats.recordCount}
                                  duration={0.6}
                                  formatNumber={formatNumber}
                                />
                              ) : (
                                "0"
                              )}
                            </div>
                          </div>

                          <div className="w-[100%] md:w-[25%] my-5 lg:my-0 bg-white p-3 text-center border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)] ">
                            <div className="text-black lg:text-[13px] sm:text-[12px] font-medium min-h-2">
                              Total Number of Bills Analysed
                            </div>
                            <div className="text-lg text-black font-bold mt-2">
                              <div>
                                {dashboardStats?.recordCount ? (
                                  <AnimatedCount
                                    value={dashboardStats.recordCount}
                                    duration={0.6}
                                    formatNumber={formatNumber}
                                  />
                                ) : (
                                  "0"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="block lg:hidden sm:w-[100%] h-[300px] sm:h-[500px] lg:ml-[80px] sm:mx-auto lg:w-[40%] bg-white p-3 border border-gray-300 rounded-[20px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)]  mb-10 sm:mb-0">
                      {process.env.REACT_APP_ENVIRONMENT === "local" ? (
                        <img
                          src="./graph.png"
                          alt=""
                          className="h-[100%] w-[100%]"
                        />
                      ) : (
                        <GoogleMapDashboard address={unitData} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between cursor-pointer gap-2  w-[95%] sm:w-[20%] rounded-xl ml-2 mb-[60px] sm:mb-0  sm:pb-0 lg:shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.13)]">
              <div className="  bg-white  p-2 h-1/4 flex justify-center items-center border border-gray-300  rounded-xl">
                <div className="  bg-[#E2F5EC] flex items-center flex-col justify-center w-full h-[100%] text-center border relative border-gray-200 rounded-[20px] ">
                  <div className="flex flex-row items-center justify-center  sm:pt-[8%] pt-4">
                    <div className="font-medium">Load Factor:</div>
                    <select
                      className="border w-12 border-gray-300 text-[12px]  bg-[#E2F5EC] rounded-lg  py-4 sm:py-2 ml-2"
                      value={selectedLF}
                      onChange={handleSelectChange}
                    >
                      <option value="">Select LF</option>
                      <option value={10}>10%</option>
                      <option value={20}>20%</option>
                      <option value={30}>30%</option>
                      <option value={40}>40%</option>
                      <option value={50}>50%</option>
                      <option value={60}>60%</option>
                      <option value={70}>70%</option>
                      <option value={80}>80%</option>
                      <option value={90}>90%</option>
                      <option value={100}>100%</option>
                    </select>
                  </div>
                  <Link to={url}>
                    <div className="flex flex-row items-center justify-center sm:mt-4 min-h-5 lg:min-h-10">
                      <div className="text-black lg:text-[15px] mt-2 sm:text-[10px] font-medium ">
                        Number of Bills : {lfData}
                      </div>
                      <div className="text-lg text-black font-bold"></div>
                    </div>
                    <div className="absolute top-0 right-2 dashboard-tooltip">
                      <CustomTooltopDashboard
                        text={
                          constantUtils.getDashboardToolTipData()
                            .DashboardLoadFactor.text
                        }
                      />
                    </div>
                  </Link>
                  <br />
                </div>
              </div>

              <div className="bg-[#C0EED8] rounded-lg border border-gray-300  flex flex-col flex-grow">
                <div className="flex-grow flex flex-col justify-center">
                  <div className="pt-[20%] text-[36px]  xl:text-[28px]  2xl:text-[32px] sm:pt-[5%] text-shadow leading-normal font-bold py-4 tracking-wide text-center">
                    Saving <br /> Through <br /> Green <br /> Energy
                  </div>
                  <div className="py-2 dashboard mx-4 sm:py-2 sm:mx-2">
                    <FilterSelect
                      value={selectedRecommendationState}
                      options={[
                        { label: "Select State", value: "" },
                        ...(recommendationStates?.map((state) => ({
                          label: state?.name,
                          value: state?.stateCode,
                        })) || []),
                      ]}
                      onChange={(e) => {
                        setSelectedRecommendationState(e.target.value);
                      }}
                    />
                  </div>
                  <div className="dashboard py-4 sm:py-2 flex flex-col justify-center mx-4 sm:mx-2">
                    <select
                      className={`p-2 rounded-lg border-none ${
                        selectedRecommedationYear !== ""
                          ? "text-black"
                          : "text-gray-400"
                      }`}
                      value={selectedRecommedationYear}
                      onChange={(e) => {
                        setSelectedRecommedationYear(e.target.value);
                      }}
                    >
                      <option className="text-gray-400"> Select Year</option>
                      {recommedationYears?.map((fyyear) => (
                        <option key={fyyear} value={fyyear}>
                          {fyyear}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="text-lg text-green-500 text-center w-full font-semibold pt-[15%] sm:pt-[3%] number-container min-h-10">
                    <div>
                      {savingEnergyLoader ? (
                        <div className="w-[20%] mx-auto my-auto">
                          <LoaderComponent width={"30"} height={"30"} />
                        </div>
                      ) : (
                        <div
                          className="cursor-pointer hover:underline"
                          onClick={() => {
                            if (
                              savingsThroughGreenMeterWise &&
                              savingsThroughGreenMeterWise?.length > 0 &&
                              !savingEnergyLoader
                            ) {
                              setOpenGreenSavingsPopup(true);
                              setActiveTab(0);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            className="mr-1"
                            icon={faIndianRupeeSign}
                          />
                          {Number(savingsThroughGreen)?.toLocaleString("en-IN")}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="text-center pb-4">
                  <div className="dashboard-tooltip">
                    <CustomTooltopDashboard
                      text={
                        constantUtils.getDashboardToolTipData().DashboardSaving
                          .text
                      }
                    />
                  </div>
                  <div className="flex justify-center w-full">
                    <p className="text-sm mx-4 pt-3 text-center sm:max-w-[200px] text-gray-800">
                      Select "Maharashtra" to get green power recommendation
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
