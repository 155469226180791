import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  getData,
  postList,
  postOcrList,
  fetchStateList,
  fetchDiscomList,
} from "../../api/apiDataExchange";
import Sidebar from "../../components/sidebar/Sidebar";
import { useAuth } from "../../hooks/useAuth";
import LoaderComponent from "../../components/loaderCompent/LoaderComponent";
import CustomTooltop from "../../components/tooltips/CustomTooltop";
import { ConstantUtils } from "../../utils/ConstantUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../components/monthYearPicker/MonthYearPicker.css";
import moment from "moment/moment";
import FilterSelect from "../../components/select/FilterSelect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { MultiSelect } from "react-multi-select-component";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const CompareOcrBills = () => {
  const constantUtils = new ConstantUtils();

  const [ocrData, setOcrData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { setIsSidebarOpen, isSidebarOpen } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(() => {
    const storedLimit = parseInt(localStorage.getItem("recordsPerPage"), 10);
    return storedLimit || 10;
  });
  const [totalCount, setTotalCount] = useState(0);
  const [consumerName, setConsumerName] = useState("");
  const [initialLoad, setInitialLoad] = useState(true);
  const [consumerNo, setConsumerNo] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endReadingDate, setEndReadingDate] = useState(null);
  const [startReadingDate, setStartReadingDate] = useState(null);
  const [consumerNoOptions, setConsumerNameOptions] = useState([]);
  const [filterChange, setFilterChange] = useState(1);
  const [
    updateQueryParamsFromQueryFilter,
    setUpdateQueryParamsFromQueryFilter,
  ] = useState(false);

  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState([]);
  const [stateCode, setStateCode] = useState([]);

  const [discoms, setDiscoms] = useState([]);
  const [discomCode, setDiscomcode] = useState([]);
  const [consumerNoWRTDiscom, setConsumerNoWRTDiscom] = useState([]);

  const [filters, setFilters] = useState({
    consumerName: "",
    consumerNo: [],
    status: ["COMPLETED", "ADDED", "MODIFIED", "VALIDATED"],
    startReadingDate: "",
    endReadingDate: "",
    stateCode: [],
    discomCode: [],
  });

  const [sortConfig, setSortConfig] = useState({
    key: "meterNumber",
    direction: "asc",
  });

  const handleConsumerNameChange = (event) => {
    setConsumerName(event.target.value);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const updateFilterFromQuery = query.get("updateFilter");
    if (updateFilterFromQuery === "true" && initialLoad) {
      const savedFilters = localStorage.getItem("compare-ocr-filters");
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);
        setFilters(parsedFilters);
      }
    }
  }, []);

  const handleSetPageLimit = (event) => {
    setLimit(Number(event.target.value));
    localStorage.setItem("recordsPerPage", event.target.value);
  };

  const handleConsumerNoChange = (event) => {
    setConsumerNo(event);
    const selectedConsumerNo = event.map((discom) => discom.value);
    console.log(selectedConsumerNo);
    setFilters({ ...filters, consumerNo: selectedConsumerNo });
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const updateFilterFromQuery = query.get("updateFilter");
    if (updateFilterFromQuery === "true") {
      setUpdateQueryParamsFromQueryFilter(true);
    }
  }, [location.search]);

  const handleStateChange = (selectedState) => {
    const { stateIds, stateCodes } = selectedState.reduce(
      (acc, selected) => {
        const foundState = states.find(
          (state) => state.code === selected.value
        );
        if (foundState) {
          acc.stateIds.push(foundState._id);
          acc.stateCodes.push(foundState.code);
        }
        return acc;
      },
      { stateIds: [], stateCodes: [] }
    );
    setStateCode(selectedState);
    setFilters({
      ...filters,
      stateCode: stateCodes,
      discomCode: [],
      consumerNo: [],
    });
    setStateId(stateIds);
    setDiscomcode([]);
    setConsumerNo([]);
    if (selectedState.length === 0) {
      setConsumerNoWRTDiscom(consumerNoOptions);
    } else {
      const uniqueConsumerOptions = consumerNoOptions.reduce(
        (accumulator, current) => {
          if (stateCodes.includes(current.stateCode)) {
            accumulator.push({
              label: current.label,
              value: current.value,
              stateCode: current.stateCode,
              discomCode: current.discomCode,
            });
          }
          return accumulator;
        },
        []
      );
      setConsumerNoWRTDiscom(uniqueConsumerOptions);
    }
  };

  const handleDiscomChange = (selectedDiscom) => {
    console.log({ selectedDiscom });
    const selectedDiscomCodes = selectedDiscom.map((discom) => discom.value);
    setConsumerNo([]);
    setDiscomcode(selectedDiscom);
    setFilters({ ...filters, discomCode: selectedDiscomCodes, consumerNo: [] });
    const uniqueConsumerOptions = consumerNoOptions.reduce(
      (accumulator, current) => {
        if (selectedDiscomCodes.includes(current.discomCode)) {
          accumulator.push({
            label: current.label,
            value: current.value,
            stateCode: current.stateCode,
            discomCode: current.discomCode,
          });
        }
        return accumulator;
      },
      []
    );
    setConsumerNoWRTDiscom(uniqueConsumerOptions);
  };

  const sortData = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  const renderSortArrow = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === "asc" ? (
        <FontAwesomeIcon icon={faArrowUp} />
      ) : (
        <FontAwesomeIcon icon={faArrowDown} />
      );
    }
    return null;
  };

  useEffect(() => {
    const getStateList = async () => {
      const response = await fetchStateList();
      const stateList = response?.data?.results;

      if (stateList) {
        const sortedStateList = stateList.sort((a, b) => {
          if (a.code === "IN-MH") return -1;
          if (b.code === "IN-MH") return 1;
          return a.name.localeCompare(b.name);
        });
        setStates(sortedStateList);
        const query = new URLSearchParams(location.search);
        const updateFilterFromQuery = query.get("updateFilter");
        if (updateFilterFromQuery === "true" && sortedStateList.length > 0) {
          const filterFromLocalStorage = JSON.parse(
            localStorage.getItem("compare-ocr-filters")
          );
          console.log({ filterFromLocalStorage });
          const filteredStateCodes = filterFromLocalStorage["stateCode"];
          console.log({ filteredStateCodes });
          if (filteredStateCodes) {
            const matchingStates = sortedStateList.filter((state) =>
              filteredStateCodes.includes(state.code)
            );
            console.log({ matchingStates });
            const updatedStateCode = matchingStates.map((state) => {
              return { label: state.name, value: state.code };
            });
            const updatedStateCodeIds = matchingStates.map((state) => {
              return state._id;
            });
            setStateCode(updatedStateCode);
            setStateId(updatedStateCodeIds);
          }
        }
      }
    };

    getStateList();
  }, []);

  useEffect(() => {
    if (stateId) {
      const getDiscomList = async () => {
        const payload = {
          data: {
            stateId,
          },
        };
        const response = await fetchDiscomList(payload);
        const discomList = response?.data?.results;
        if (discomList) {
          const sortedDiscomList = discomList.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setDiscoms(sortedDiscomList);
          const query = new URLSearchParams(location.search);
          const updateFilterFromQuery = query.get("updateFilter");
          if (updateFilterFromQuery === "true" && sortedDiscomList.length > 0) {
            const filterData = JSON.parse(
              localStorage.getItem("compare-ocr-filters")
            );
            const filteredDiscomCodes = filterData["discomCode"];
            const filteredConsumerNos = filterData["consumerNo"];
            console.log({ filteredDiscomCodes });
            console.log({ sortedDiscomList });
            if (filteredDiscomCodes && filteredDiscomCodes.length > 0) {
              let matchingDiscoms = sortedDiscomList.filter((dis) =>
                filteredDiscomCodes.includes(dis.code)
              );

              console.log({ matchingDiscoms });
              const updatedDiscomCode = matchingDiscoms.map((dis) => {
                return { label: dis.name, value: dis.code };
              });
              console.log({ updatedDiscomCode });
              setDiscomcode(updatedDiscomCode);
              //   //   updated consumers
              console.log({ consumerNoOptions });
              const selectedDiscomCodes = updatedDiscomCode.map(
                (discom) => discom.value
              );
              setConsumerNo([]);

              const uniqueConsumerOptions = consumerNoOptions.reduce(
                (accumulator, current) => {
                  if (selectedDiscomCodes.includes(current.discomCode)) {
                    accumulator.push({
                      label: current.label,
                      value: current.value,
                      stateCode: current.stateCode,
                      discomCode: current.discomCode,
                    });
                  }
                  return accumulator;
                },
                []
              );
              console.log({ uniqueConsumerOptions });
              setConsumerNoWRTDiscom(uniqueConsumerOptions);
              console.log({ filteredConsumerNos });
              if (filteredConsumerNos && filteredConsumerNos.length > 0) {
                const consumerNoToBeSelected = uniqueConsumerOptions.filter(
                  (dis) => filteredConsumerNos.includes(dis.value)
                );
                console.log({ consumerNoToBeSelected });
                setConsumerNo(consumerNoToBeSelected);
              }
            }
          }
        }
      };
      getDiscomList();
    } else {
      setDiscoms([]);
    }
  }, [stateId, consumerNoOptions]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const consumerNoFromQuery = query.get("conNo");
    const startReadingDate = query.get("startDate");
    const endReadingDate = query.get("endDate");
    const redirect = query.get("redirect") ;
    console.log({redirect})
    if (consumerNoFromQuery && startReadingDate && endReadingDate) {
      fetchDataByConsumerNo(
        consumerNoFromQuery,
        startReadingDate,
        endReadingDate,
        redirect
      );
    }
  }, [location.search]);

  const fetchDataByConsumerNo = async (
    consumerNo,
    startReadingDate,
    endReadingDate,
    redirect
  ) => {
    try {
      console.log({redirect});
      
      const convertTimeStart = (dateStr) => {
        const date = moment(dateStr, "MM/YYYY").startOf("month").utc();
        return date.format("YYYY-MM-DDT00:00:00.000") + "Z";
      };

      const convertTimeEnd = (dateStr) => {
        const date = moment(dateStr, "MM/YYYY").endOf("month").utc();
        return date.format("YYYY-MM-DDT00:00:00.000") + "Z";
      };

      const filters = {
        consumerNo,
        startReadingDate: convertTimeStart(startReadingDate),
        endReadingDate: convertTimeEnd(endReadingDate),
      };

      const data = { filter: filters, page: 1, limit: 1 };
      const res = await postOcrList(
        `${process.env.REACT_APP_API_URL}/ocr/list`,
        data
      );

      if (res.data.results.length > 0) {
        const jobId = res.data.results[0].jobId;
        if (redirect === "true") {
          navigate(`/compare-ocr-bills/${jobId}?redirect=true`, {
            replace: true,
          });
        } else if(redirect === 'analytics'){
          navigate(`/compare-ocr-bills/${jobId}?redirect=analytics`, {
            replace: true,
          });
        }
        
        else {
          navigate(`/compare-ocr-bills/${jobId}`, { replace: true });
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
      setLoading(false);
    }
  };

  const convertToStartDate = (dateString) => {
    const [month, year] = dateString.split("/");
    // Create a moment object for the first day of the specified month (UTC)
    const firstDayOfMonth = moment
      .utc(`${year}-${month}-01`, "YYYY-MM-DD")
      .startOf("day");
    return firstDayOfMonth.toDate();
  };

  const convertToEndDate = (dateString) => {
    const [month, year] = dateString.split("/");
    // Convert to the last day of the month using momentP
    const date = moment
      .utc(`${year}-${month}-01`, "YYYY-MM-DD")
      .startOf("month")
      .toDate();
    return date;
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const query = new URLSearchParams(location.search);
        const updateFilterFromQuery = query.get("updateFilter");
        let data;
        if (updateFilterFromQuery === "true" && initialLoad) {
          const filterFromLocalStorage = JSON.parse(
            localStorage.getItem("compare-ocr-filters")
          );
          console.log({ filterFromLocalStorage });
          setConsumerName(filterFromLocalStorage["consumerName"]);
          if (
            filterFromLocalStorage["status"] &&
            typeof filterFromLocalStorage["status"] === "string"
          ) {
            setStatus(filterFromLocalStorage["status"]);
          }

          const filteredStartDate = filterFromLocalStorage["startReadingDate"];
          const filteredEndDate = filterFromLocalStorage["endReadingDate"];
          console.log({ filteredStartDate, filteredEndDate });
          if (filteredStartDate && filteredStartDate !== "") {
            handleStartDateChange(filteredStartDate);
          }

          if (filteredEndDate && filteredEndDate !== "") {
            handleEndDateChange(filteredEndDate);
          }

          data = {
            filter: filterFromLocalStorage,
            page: currentPage, // Pass current page
            limit: limit,
            sortBy: sortConfig.key,
            sortOrder: sortConfig.direction === "asc" ? "asc" : "desc", // Pass limit for items per page
          };
        } else {
          data = {
            filter: filters,
            page: currentPage, // Pass current page
            limit: limit,
            sortBy: sortConfig.key,
            sortOrder: sortConfig.direction === "asc" ? "asc" : "desc", // Pass limit for items per page
          };
        }

        const res = await postOcrList(
          `${process.env.REACT_APP_API_URL}/ocr/list`,
          data
        );

        setOcrData(res.data.results); // Paginated data
        setTotalCount(res.data.total); // Total count from the backend
        setLoading(false);
        setInitialLoad(false);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [currentPage, limit, sortConfig, filterChange, location.search]); // Trigger fetch on page or limit change

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage); // Update current page
  };

  const totalPages = Math.ceil(totalCount / limit); // Calculate total pages

  const handleSearch = async () => {
    setCurrentPage(1);
    let updatedFilters = { ...filters };
    console.log({ updatedFilters });

    // Update the copy with consumerName if it exists
    if (consumerName !== "") {
      updatedFilters = { ...updatedFilters, consumerName: consumerName };
    }

    if (status) {
      updatedFilters = { ...updatedFilters, status: status };
    }

    if (startDate) {
      updatedFilters = {
        ...updatedFilters,
        startReadingDate: startReadingDate,
        endReadingDate: endReadingDate,
      };
    }
    setFilters(updatedFilters);
    console.log(updatedFilters);
    setFilterChange((prev) => prev + 1);
  };

  const handleReset = () => {
    const query = new URLSearchParams(location.search);
    const updateFilterFromQuery = query.get("updateFilter");
    let data;
    if (updateFilterFromQuery === "true") {
      navigate("/compare-ocr-bills");
      setFilterChange((prev) => prev + 1);
      setConsumerName("");
      setConsumerNo("");
      setStatus("");
      setStartDate(null);
      setEndDate(null);
      setFilters({
        consumerName: "",
        consumerNo: "",
        status: ["COMPLETED", "ADDED", "MODIFIED", "VALIDATED"],
        startReadingDate: "",
        endReadingDate: "",
        stateCode: "",
        discomCode: "",
      });
      setStateId(null);
      setDiscoms([]);
      setDiscomcode("");
      setStateCode([]);
    } else {
      setFilterChange((prev) => prev + 1);
      setConsumerName("");
      setConsumerNo("");
      setStatus("");
      setStartDate(null);
      setEndDate(null);
      setFilters({
        consumerName: "",
        consumerNo: "",
        status: ["COMPLETED", "ADDED", "MODIFIED", "VALIDATED"],
        startReadingDate: "",
        endReadingDate: "",
        stateCode: "",
        discomCode: "",
      });
      setStateId(null);
      setDiscoms([]);
      setDiscomcode("");
      setStateCode([]);
    }
  };

  function capitalizeWords(str) {
    return str?.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
  }

  const handleStartDateChange = (date) => {
    let givenDate = new Date(date);
    let month = givenDate.getMonth();
    let year = givenDate.getFullYear();
    let nextMonth = new Date(year, month + 1, 1);
    let lastDateOfMonth = new Date(nextMonth - 1);
    let firstDayOfMonth = new Date(Date.UTC(year, month, 1));

    setStartDate(lastDateOfMonth);
    setStartReadingDate(firstDayOfMonth);
  };

  const handleEndDateChange = (date) => {
    let givenDate = new Date(date);
    let month = givenDate.getMonth();
    let year = givenDate.getFullYear();
    let nextMonth = new Date(year, month + 1, 1);
    let lastDateOfMonth = new Date(nextMonth - 1);
    // let firstDayOfMonth = new Date(Date.UTC(year, month, 1));
    setEndDate(lastDateOfMonth);
    setEndReadingDate(lastDateOfMonth);
  };

  const convertIsoToDate = (date) => {
    if (!moment(date, moment.ISO_8601, true).isValid()) {
      return "";
    }
    return moment(date).format("MM/YYYY");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const organisationsResponse = await postList(
          `${process.env.REACT_APP_API_URL}/organisation/list`
        );
        const organisationsList = organisationsResponse?.data?.results;
        const organisationIds = organisationsList.map((org) => org._id);
        if (organisationIds?.length > 0) {
          const organisationsData = await Promise.all(
            organisationIds.map((orgId) =>
              getData(
                `${process.env.REACT_APP_API_URL}/organisation/tree/${orgId}`
              )
            )
          );

          const allFactories = organisationsData?.flatMap((data) =>
            (data?.data?.factories || []).map((factory) => ({
              ...factory,
              organisationId: data.data._id,
              name: data.name,
            }))
          );

          const allUnits = allFactories?.flatMap((data) =>
            (data?.units || []).map((unit) => ({
              ...unit,
              factoryId: data._id,
            }))
          );

          const allMeters = allUnits?.flatMap((data) =>
            (data?.meters || []).map((meter) => ({
              ...meter,
              unitId: data._id,
              name: data.name,
              stateCode: data.stateCode,
              discomCode: data.discomCode,
            }))
          );

          const uniqueConsumerOptions = allMeters.reduce(
            (accumulator, current) => {
              if (
                !accumulator.some(
                  (option) => option.value === current.consumerNo
                )
              ) {
                accumulator.push({
                  label: current.consumerNo + " (" + current.name + ")",
                  value: current.consumerNo,
                  stateCode: current.stateCode,
                  discomCode: current.discomCode,
                });
              }
              return accumulator;
            },
            []
          );
          setConsumerNameOptions(uniqueConsumerOptions);
          setConsumerNoWRTDiscom(uniqueConsumerOptions);
        }
        setLoading(false);
      } catch (error) {
        console.error("An error occurred while fetching data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  const getCurrentDateTime = () => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    return `${year}${month}${day}_${hours}${minutes}${seconds}`;
  };


  useEffect(() => {
console.log({tableHeight})
  }, [tableHeight])
  

  const handleDownload = async () => {
    try {
      const data = {
        filter: filters,
        page: currentPage, // Pass current page
        limit: limit,
        sortBy: sortConfig.key,
        sortOrder: sortConfig.direction === "asc" ? "asc" : "desc", // Pass limit for items per page
      };

      let payload = {};
      if (data) {
        payload = {
          jobId: data.jobId,
          status: data.filter && data.filter?.status,
          pageSize: data.limit,
          page: data.page,
          consumerName: data.filter && data.filter?.consumerName,
          consumerNo: data.filter && data.filter?.consumerNo,
          startReadingDate: data.filter && data.filter?.startReadingDate,
          endReadingDate: data.filter && data.filter?.endReadingDate,
          sortBy:
            data?.sortBy === "billMonthOfLastDay"
              ? data?.sortBy
              : `data.${data?.sortBy}.value`,
          sortOrder: data?.sortOrder,
          stateCode: data?.filter?.stateCode,
          discomCode: data?.filter?.discomCode,
        };
      }

      // Fetch the file from the API endpoint
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/ocr/download-report`,
        {
          buyerId: localStorage.getItem("userId"),
          ...payload,
        },
        {
          responseType: "blob", // Ensure the response is treated as a blob
        }
      );

      let filename = `${getCurrentDateTime()}.csv`;
      const contentDisposition = response.headers["content-disposition"];

      if (
        contentDisposition &&
        contentDisposition.indexOf("attachment") !== -1
      ) {
        const match = contentDisposition.match(/filename="(.+)"/);
        if (match && match.length === 2) {
          filename = match[1].replace(/\.[^/.]+$/, ".csv"); // Replace any extension with .csv
        }
      }

      const blob = new Blob([response.data], { type: "text/csv" }); // Set MIME type for CSV
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      toast.error("Error Downloading File. Please try again later.", {
        position: "top-center",
      });
      console.error("Download failed:", error);
    }
  };

  const navigateToCompareOcr = (id) => {
    navigate(`/compare-ocr-bills/${id}?navigatedFrom=compare-ocr-bills`);
    localStorage.setItem("compare-ocr-filters", JSON.stringify(filters));
  };

  useEffect(() => {
    console.log({ tableHeight });
  }, [tableHeight]);
  // Function to dynamically calculate height
  const calculateHeight = () => {
    const windowHeight = window.innerHeight - 360;

    setTableHeight(windowHeight);
  };

  useEffect(() => {
    // Calculate height on component mount
    calculateHeight();

    // Add event listener to handle window resize
    window.addEventListener("resize", calculateHeight);

    // Clean up event listener on unmount
    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  return (
    <div className="sm:max-w-[1920px] mt-[70px] sm:mt-[100px]">
      <div className="hidden sm:block">
        <Sidebar />
      </div>
      <div
        className={`${
          isSidebarOpen ? "sm:ml-[315px]" : "sm:ml-[95px]"
        } flex-1 sm:mb-8 sm:mr-1 transition-width duration-300`}
      >
        {/* <div className="ml-[315px] flex-1 mb-8 mr-1"> */}{" "}
        <div className="p-4 rounded-lg w-[100%] flex flex-col sm:pb-[5px] sm:shadow-[2px_2px_2px_-1px_rgb(0,0,0,0.1),-1px_-1px_4px_2px_rgb(0,0,0,0.1)] md:mt-[75px]">
          <div className="flex justify-between items-center py-2">
            <div className="text-xl sm:text-[28px] font-medium leading-[42px]">
              Compare OCR Bills
            </div>

            <button
              onClick={handleDownload}
              className="text-white bg-[#067C4E] hover:bg-[#045036] w-[180px] ml-3 h-[40px]  sm:py-2 px-4 rounded-lg"
            >
              Download File
            </button>
          </div>

          <div className="flex flex-col sm:flex-row lg:justify-start gap-4 sm:items-center w-full mt-2 mb-2 ">
            <div
              className={`${
                isSidebarOpen
                  ? "sm:w-[35%] "
                  : "sm:w-[15%]"
              }`}
            >
              <input
                type="text"
                value={consumerName}
                placeholder="Enter consumer name"
                onChange={handleConsumerNameChange}
                className="border border-[gray] h-10 w-full rounded-lg p-1"
              />
            </div>
            <div
              className={`border rounded-[10px] outline-none border-gray-500 lg:mt-0 compare-ocr w-[100%] ${
                isSidebarOpen
                  ? "sm:max-w-[35%] "
                  : "sm:max-w-[15%]  sm:min-w-[15%]"
              }`}
            >
              <MultiSelect
                options={states?.map((state) => {
                  return { label: state?.name, value: state?.code };
                })}
                value={stateCode ?? []}
                onChange={handleStateChange}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select state code",
                  allItemsAreSelected: "All States selected",
                }}
                disableSearch={true}
                className="custom-multi-select"
              />
            </div>

            <div
              className={`border rounded-[10px] outline-none border-gray-500 lg:mt-0 compare-ocr w-[100%] ${
                isSidebarOpen
                  ? "sm:w-[35%]"
                  : "sm:max-w-[15%]  sm:min-w-[15%]"
              }`}
            >
              <MultiSelect
                options={discoms?.map((discom) => {
                  return { label: discom?.name, value: discom?.code };
                })}
                value={discomCode ?? []}
                onChange={handleDiscomChange}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select discom code",
                  allItemsAreSelected: "All Discoms selected",
                }}
                disableSearch={true}
                className="custom-multi-select"
              />
            </div>
            <div
              className={`border rounded-[10px] outline-none border-gray-500 lg:mt-0 compare-ocr w-[100%] ${
                isSidebarOpen
                  ? "sm:w-[35%]"
                  : "sm:w-[15%]"
              }`}
            >
              <MultiSelect
                options={consumerNoWRTDiscom}
                value={consumerNo ?? []}
                onChange={handleConsumerNoChange}
                labelledBy="Select"
                overrideStrings={{
                  selectSomeItems: "Select Consumer No",
                  allItemsAreSelected: "All units selected",
                }}
                disableSearch={true}
                className="custom-multi-select"
              />
            </div>
            <div
              className={`border rounded-[10px] outline-none border-gray-500 lg:mt-0 w-[100%] ${
                isSidebarOpen
                  ? "sm:w-[35%]"
                  : "sm:w-[15%]"
              }`}
            >
              <FilterSelect
                options={[
                  { value: "COMPLETED", label: "Completed" },
                  { value: "ADDED", label: "Added" },
                  { value: "MODIFIED", label: "Modified" },
                  { value: "VALIDATED", label: "Validated" },
                ]}
                value={status}
                onChange={handleStatusChange}
                label="by status"
              />
            </div>

            <div
              className={`lg:mt-0 sm:ml-[10px] lg:ml-0 compare-ocr w-[100%] ${
                isSidebarOpen
                  ? "sm:w-[35%]"
                  : "sm:w-[15%]"
              }`}
            >
              <div className="customDatePickerWidth z-[40]">
                <DatePicker
                  selected={startDate}
                  onChange={handleStartDateChange}
                  dateFormat="MMM-yyyy"
                  selectsStart
                  showMonthYearPicker
                  startDate={startDate}
                  endDate={endDate}
                  placeholderText="Select Start Month"
                  maxDate={new Date()}
                  className="w-full"
                />
              </div>
            </div>
            <div
              className={`lg:mt-0 lg:ml-0 compare-ocr z-[40] w-[100%] ${
                isSidebarOpen
                  ? "sm:w-[35%] "
                  : "sm:w-[15%]"
              }`}
            >
              <div className="customDatePickerWidth">
                <DatePicker
                  selected={endDate}
                  onChange={handleEndDateChange}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  dateFormat="MMM-yyyy"
                  showMonthYearPicker
                  placeholderText="Select End Month"
                  maxDate={new Date()}
                  className="w-full"
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end my-2 mx-2 md:mx-2">
            <button
              onClick={handleSearch}
              className="text-white bg-[#067C4E] hover:bg-[#045036] w-[100px] py-2 px-4 rounded-lg"
            >
              Filter
            </button>
            <button
              onClick={handleReset}
              className="bg-white border-[#067C4E] hover:bg-gray-200 text-[#067C4E] border-2 border-solid w-[100px] py-2 px-4 ml-3 rounded-lg"
            >
              Reset
            </button>
          </div>
          {loading ? (
            <LoaderComponent />
          ) : (
            <>
              <div className="relative bg-white">
                <div
                  style={{ height: `${tableHeight}px` }}
                  className=" overflow-y-auto upload-bills"
                >
                  {" "}
                  {/* Table container for scroll */}
                  <table className="w-full">
                    <thead className="sticky top-0 bg-[#EFEFEF] z-5">
                      <tr className="text-left">
                        <th
                          className="p-4 cursor-pointer w-[20%]"
                          onClick={() => sortData("meterNumber")}
                        >
                          Meter Number {renderSortArrow("meterNumber")}
                        </th>
                        <th
                          className="cursor-pointer w-[22%]"
                          onClick={() => sortData("consumerName")}
                        >
                          Consumer Name {renderSortArrow("consumerName")}
                        </th>
                        <th
                          className="cursor-pointer w-[15%]"
                          onClick={() => sortData("consumerNo")}
                        >
                          Consumer No {renderSortArrow("consumerNo")}
                        </th>
                        <th
                          className="cursor-pointer w-[15%]"
                          onClick={() => sortData("readingDate")}
                        >
                          Reading Date {renderSortArrow("readingDate")}
                        </th>
                        <th
                          className="cursor-pointer w-[15%]"
                          onClick={() => sortData("billMonthOfLastDay")}
                        >
                          Bill Month {renderSortArrow("billMonthOfLastDay")}
                        </th>
                        <th className="py-7 sm:py-4 flex items-center justify-start space-x-2 cursor-pointer w-[15%]">
                          <span>Status</span>
                          <CustomTooltop
                            text={
                              constantUtils.getStatusToolTipData().Status.text
                            }
                            size="sm"
                          />
                        </th>
                        <th className="w-[15%]">Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {ocrData.length === 0 ? (
                        <tr>
                          <td
                            colSpan={7}
                            className="w-screen px-6 py-2 text-sm leading-2 text-gray-500 text-center"
                          >
                            No Data Available
                          </td>
                        </tr>
                      ) : (
                        ocrData?.map((item, index) => {
                          return (
                            <tr
                              key={index}
                              className="text-left text-[#5d5c5c]"
                            >
                              <td className="px-4 py-2 w-[20%]">
                                {item.data?.meterNo?.value}
                              </td>
                              <td className="w-[22%]">
                                {capitalizeWords(item.data.consumerName.value)}
                              </td>
                              <td className="w-[15%]">
                                {item.data.consumerNo.value}
                              </td>
                              <td className="w-[15%]">
                                {item.data?.readingDate?.value}
                              </td>
                              <td className="w-[15%]">
                                {item.data?.readingDate?.value
                                  ? convertIsoToDate(item?.billMonthOfLastDay)
                                  : ""}
                              </td>
                              <td className="w-[15%]">
                                {capitalizeWords(item.data.status)}
                              </td>
                              <td
                                onClick={() => navigateToCompareOcr(item.jobId)}
                                className="cursor-pointer  pr-4 text-[blue] w-[15%]"
                              >
                                Validate
                              </td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </table>
                </div>
              </div>

              {ocrData.length !== 0 && (
                <div className="pagination-controls border-t-2 border-[#abaaaa] w-full flex flex-row items-center justify-between pt-1">
                  {/* Items per page dropdown */}
                  <div className="mr-6">
                    <label htmlFor="itemsPerPage" className="mr-2">
                      Items per page:
                    </label>
                    <select
                      id="itemsPerPage"
                      value={limit}
                      onChange={handleSetPageLimit}
                      className=" px-3 py-1 rounded-lg border border-gray-300"
                    >
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                  </div>
                  <div>
                    {/* Previous button */}
                    <button
                      className={`bg-[#e1e0e0] px-3 py-1 rounded-xl`}
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>

                    {/* Page info */}
                    <span className="mx-4">
                      {totalCount > 0
                        ? `${(currentPage - 1) * limit + 1} to ${Math.min(
                            currentPage * limit,
                            totalCount
                          )} of ${totalCount}`
                        : "0"}
                    </span>

                    {/* Next button */}
                    <button
                      className="bg-[#e1e0e0] px-3 py-1 rounded-xl"
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <ToastContainer position="top-center" autoClose={3000} />
    </div>
  );
};

export default CompareOcrBills;
